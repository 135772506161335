import { generatePaginate } from '@helpers/utils';
import { Icommon } from '@models/general';

export const state: Icommon = {
  flows: [],
  listSelectflows: [],
  loadingSelect: false,
  pagination: {
    selectFlow: generatePaginate()
  }
};
