import { Icommon } from '@models/general';
import { store } from 'quasar/wrappers';
import Vuex from 'vuex';
import AdditionalFieldsModule from './additional_fields';
import AdminModule from './admin';
import ChannelModule from './channel';
import FacebookModule from './facebook';
import FlowModule from './flow';
import InstagramModule from './instagram';
import PermissionModule from './permission';
import SigninModule from './signin';
import StorageModule from './storage';
import TeamModule from './team';
import SettingsModule from './config';
import HsmModule from './hsm';
import MarketplaceModule from './marketplace';
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export interface StateInterface {
  // Define your own store structure, using submodules if needed
  // example: ExampleStateInterface;
  // Declared as unknown to avoid linting issue. Best to strongly type as per the line above.
  SigninModule: Icommon;
  AdminModule: Icommon;
  ChannelModule: Icommon;
  FlowModule: Icommon;
  FacebookModule: Icommon;
  InstagramModule: Icommon;
  StorageModule: Icommon;
  TeamModule: Icommon;
  AdditionalFieldsModule: Icommon;
  MarketplaceModule: Icommon;
  HsmModule: Icommon;
  SettingsModule: Icommon;
}

export default store(function({ Vue }) {
  Vue.use(Vuex);

  const Store = new Vuex.Store<StateInterface>({
    modules: {
      SigninModule,
      AdminModule,
      ChannelModule,
      FlowModule,
      FacebookModule,
      InstagramModule,
      StorageModule,
      TeamModule,
      AdditionalFieldsModule,
      PermissionModule,
      SettingsModule,
      HsmModule,
      MarketplaceModule
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: !!process.env.DEBUGGING
  });

  return Store;
});
