import { formValidate } from '@helpers/validate';
import { generatePaginate } from '@helpers/utils';
import { Icommon } from '@models/general';

export const state: Icommon = {
  loading: false,
  loadingSelect: false,
  loadingSidebar: false,
  grid: false,
  searchDone: false,
  filter: '',
  isClone: false,
  modalExampleModel: {
    openModal: false
  },
  isRegister: false,
  model: null,
  listHSM: [],
  sidebarRight: {
    device: false,
    template: false
  },
  listFields: [],
  listPredefinedFields: [],
  hsmForm: {},
  listPlatforms: [],
  listHsmChannels: [],
  listHsmChannelsSelected: [],
  listDeviceSidebar: [],
  listSelectDevices: [],
  formRules: formValidate(),
  pagination: {
    hsmTemplates: generatePaginate(),
    selectPaginationdevices: generatePaginate()
  },
  existsChannelsNotDialog: null,
  hsmChannelsSaved: false
};
