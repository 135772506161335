import { generatePaginate } from '@helpers/utils';
import { Icommon, IPagination } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  SET_LOADING_SELECT(state, value: boolean) {
    state.loadingSelect = value;
  },
  ADD_ITEM_SELECT_FLOWS(state, value) {
    state.listSelectflows.push({ ...value });
  },
  ADD_LIST_SELECT_FLOWS(state, value) {
    state.listSelectflows = [...state.listSelectflows, ...value];
  },
  SET_LIST_SELECT_FLOWS(state, value) {
    state.listSelectflows = value;
  },
  SET_PAGINATION_SELECT_FLOWS(state, value: IPagination) {
    state.pagination.selectFlow = generatePaginate(value);
  }
};
