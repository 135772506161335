import { $apollo } from '@boot/apollo';
import { query } from '@graphql';
import { Icommon } from '@models/general';
import { ActionTree } from 'vuex';

export const actions: ActionTree<Icommon, any> = {
  async GET_LIST_SELECT_FLOWS({ commit }): Promise<void> {
    commit('SET_LOADING_SELECT', true);
    await $apollo
      .query({ query: query.GET_LIST_SELECT_FLOW })
      .then(({ data: { flows } }) => {
        commit('SET_LIST_SELECT_FLOWS', flows.items);
        commit('SET_PAGINATION_SELECT_FLOWS', flows.meta);
      })
      .finally(() => {
        commit('SET_LOADING_SELECT', false);
      });
  },
  async SEARCH_FLOWS({ state, commit }, search): Promise<void> {
    if (search && search.length >= 3) {
      await $apollo
        .query({ query: query.SEARCH_FLOWS, variables: { search } })
        .then(({ data: { flows } }) => {
          flows.items.forEach((flow: Icommon) => {
            const index = state.listSelectflows.findIndex(
              (item: Icommon) => item._id === flow._id
            );

            if (index === -1) {
              commit('ADD_ITEM_SELECT_FLOWS', flow);
            }
          });
        });
    }
  },
  async VIRTUAL_SCROLL_FLOWS({ state, commit }, { to, ref, direction }) {
    const pagination = state.pagination.selectFlow;
    const lastIndex = state.listSelectflows.length - 1;

    if (
      state.loadingSelect !== true &&
      direction === 'increase' &&
      to === lastIndex
    ) {
      const limit = pagination.itemsPerPage;
      const page_next = +pagination.currentPage + 1;

      let page = 0;

      if (page_next <= pagination.totalPages) {
        page = page_next;
      } else {
        return false;
      }

      commit('SET_LOADING_SELECT', true);

      await $apollo
        .query({
          query: query.GET_FLOWS_PAGINATION,
          variables: {
            page,
            limit
          }
        })
        .then(({ data: { flows } }) => {
          commit('ADD_LIST_SELECT_FLOWS', flows.items);
          commit('SET_PAGINATION_SELECT_FLOWS', flows.meta);
        })
        .finally(() => {
          commit('SET_LOADING_SELECT', false);
          ref.refresh();
        });
    }
  }
};
