import sz_lang from '@helpers/translate';
import { Icommon, Iselect } from '@models/general';
import { Ifields } from '@models/model';
import { GetterTree } from 'vuex';
const { translate } = sz_lang();

export const getters: GetterTree<Icommon, any> = {
  statusOptions(): Iselect[] {
    return [
      { label: translate.value.approved, value: 'approved' },
      { label: translate.value.pending, value: 'pending' },
      { label: translate.value.unsynchronized, value: 'unsynchronized' },
      { label: translate.value.refused, value: 'refused' }
    ];
  },
  fieldsOptions: (state): Iselect => {
    return state.listPredefinedFields.map((item: Ifields) => {
      return {
        label: item.name,
        value: `{{${item.name}}}`.toString()
      };
    });
  },
  buttonActionTypesOptions(): Iselect[] {
    return [
      { value: 'URL', label: 'URL' },
      { value: 'PHONE_NUMBER', label: translate.value.hsm_screen.phone }
    ];
  },
  templateButtonExample() {
    return [
      { buttons: [], value: 0 },
      { buttons: [{ text: translate.value.list_title }], value: 1 },
      {
        buttons: [{ text: 'Site' }, { text: translate.value.turn_on }],
        value: 2
      },
      {
        buttons: [{ text: '1' }, { text: '2' }, { text: '3' }],
        value: 3
      },
      {
        buttons: [{ text: translate.value.see_items }],
        value: 4,
        type: 'MULTIPRODUCT'
      },
      {
        buttons: [
          { text: translate.value.authentication_template.button_message }
        ],
        value: 5,
        type: 'AUTHENTICATION'
      }
    ];
  },
  buttonTypesOptions(): Iselect[] {
    return [
      {
        value: 'QUICK_REPLY',
        label: translate.value.hsm_screen.button_type_quick_reply
      },
      {
        value: 'ACTION',
        label: translate.value.hsm_screen.button_type_action
      },
      {
        value: 'LIST',
        label: translate.value.list
      }
    ];
  },
  headTypesOptions(): Iselect[] {
    return [
      { value: '', label: translate.value.hsm_screen.head_type_none },
      { value: 'TEXT', label: translate.value.hsm_screen.head_type_text },
      { value: 'IMAGE', label: translate.value.hsm_screen.head_type_image },
      {
        value: 'DOCUMENT',
        label: translate.value.hsm_screen.head_type_document
      },
      { value: 'VIDEO', label: translate.value.hsm_screen.head_type_video }
    ];
  },
  categoriesOptions(): { [key: string]: Iselect[] } {
    return {
      'v14.0': [
        {
          value: 'TRANSACTIONAL',
          label: 'TRANSACTIONAL'
        },
        {
          value: 'MARKETING',
          label: 'MARKETING'
        },
        {
          value: 'OTP',
          label: 'OTP'
        }
      ],
      'v16.0': [
        {
          value: 'UTILITY',
          label: 'UTILITY'
        },
        {
          value: 'MARKETING',
          label: 'MARKETING'
        },
        {
          value: 'AUTHENTICATION',
          label: 'AUTHENTICATION'
        }
      ],
      'v16.1': [
        {
          value: 'UTILITY',
          label: 'UTILITY'
        },
        {
          value: 'MARKETING',
          label: 'MARKETING'
        },
        {
          value: 'AUTHENTICATION',
          label: 'AUTHENTICATION'
        },
        {
          value: 'SERVICE',
          label: 'SERVICE'
        }
      ]
    };
  },
  hsmVisibilityOptions(): Iselect[] {
    return [
      {
        value: 'all',
        label: translate.value.hsm_visibility.all
      },
      {
        value: 'agents_only',
        label: translate.value.hsm_visibility.agents_only
      },
      {
        value: 'admins_only',
        label: translate.value.hsm_visibility.admins_only
      },
      {
        value: 'none',
        label: translate.value.hsm_visibility.none
      }
    ];
  }
};
