import { generatePaginate } from '@helpers/utils';
import { IPagination } from '@models/general';
import { Icommon } from '@models/general';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<Icommon> = {
  SET_CHANNELS_NOT_DIALOG(state, value: boolean) {
    state.existsChannelsNotDialog = value;
  },
  SET_LOADING(state, value: boolean) {
    state.loading = value;
  },
  SET_lOADING_SIDEBAR(state, value: boolean) {
    state.loadingSidebar = value;
  },
  SET_FILTER(state, value: string) {
    state.filter = value;
  },
  SET_SEARCH_DONE(state, value: boolean) {
    state.searchDone = value;
  },
  SET_CLONE(state, value: boolean) {
    state.isClone = value;
  },
  SET_LIST_HSM(state, value) {
    state.listHSM = [...value];
  },
  SET_HSM_CHANNELS(state, value) {
    state.listHsmChannels = value;
  },
  SET_HSM_CHANNELS_SELECTED(state, value) {
    state.listHsmChannelsSelected = value;
  },
  SET_LIST_SELECT_DEVICES(state, value: Icommon[]) {
    state.listSelectDevices = [...value];
  },
  SET_PAGINATION_SELECT_DEVICES(state, value: IPagination) {
    state.pagination.selectPaginationdevices = generatePaginate(value);
  },
  ADD_ITEM_SELECT_DEVICES(state, value) {
    state.listSelectDevices.push({ ...value });
  },
  ADD_LIST_SELECT_DEVICES(state, value) {
    state.listSelectDevices = [...state.listSelectDevices, ...value];
  },
  SET_CHANNELS_SIDEBAR(state, value: Icommon[]) {
    state.listDeviceSidebar = [...value];
  },
  SET_LOADING_SELECT(state, value: boolean) {
    state.loadingSelect = value;
  },
  SET_LIST_FIELDS(state, value: Icommon[]) {
    state.listFields = [...value];
  },
  SET_LIST_PREDEFINED_FIELDS(state, value: Icommon[]) {
    state.listPredefinedFields = value;
  },
  SET_LIST_PLATFORMS(state, value: Icommon[]) {
    state.listPlatforms = [...value];
  },
  SET_PAGINATION_HSM(state, value: IPagination) {
    state.pagination.hsmTemplates = generatePaginate(value);
  },
  TOGGLE_GRID(state, value: boolean) {
    state.grid = value;
  },
  OPEN_MODAL_EXAMPLE_MODEL(state, value: Icommon) {
    state.modalExampleModel = {
      openModal: value?.openModal || false
    };
  },
  OPEN_SIDEBAR_DEVICE(state, value: boolean) {
    state.sidebarRight.device = value;
  },
  OPEN_SIDEBAR_TEMPLATE(state, value: boolean) {
    state.sidebarRight.template = value;
  },
  SET_UPDATE_LIMIT_PAGINATION_HSM(state, value: number) {
    state.pagination.hsmTemplates.itemsPerPage = value;
  },
  SET_IS_REGISTER(state, value: boolean) {
    state.isRegister = value;
  },
  SET_TEMPLATE(state, value = {}) {
    state.hsmForm = { ...value };
  },
  SET_MODEL(state, value: number | null) {
    state.model = value;
  },
  SET_HSM_CHANNELS_SAVED(state, value: boolean) {
    state.hsmChannelsSaved = value;
  }
};
