import { query, mutation } from '@graphql';
import { $apollo } from '@boot/apollo';
import { Icommon } from '@models/general';
import { ActionTree } from 'vuex';
import { notification, requestPagination, prepareGraphQLObject } from '@helpers/utils';
import { legacySZ } from '@boot/axios';
import { transformFilterObject } from '@pages/admin/Hsm/utils/template_utils';
import sz_lang from '@helpers/translate';

interface Isearch {
  name: string;
  message: string;
  channel: string;
  status: string;
}

const { translate } = sz_lang();

export const actions: ActionTree<Icommon, any> = {
  async ASYNC_TEMPLATES({ commit }): Promise<void> {
    commit('SET_LOADING', true);
    await legacySZ.get('hsm/sync').finally(() => {
      commit('SET_LOADING', false);
    });
  },
  async REQUEST_ALL({ commit }): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .query({
        query: query.GET_ALL_HSM
      })
      .then(({ data }) => {
        const {
          getPlatformsDefault,
          additionalFields,
          predefinedStaticFields,
          hsmTemplates,
          hsmChannels
        } = data;
        const predefinedFields = [
          ...predefinedStaticFields,
          ...additionalFields.items
        ];
        commit('SET_LIST_PREDEFINED_FIELDS', predefinedFields);
        commit('SET_LIST_FIELDS', additionalFields.items);
        commit('SET_LIST_PLATFORMS', getPlatformsDefault);
        commit('SET_LIST_HSM', hsmTemplates.items);
        commit('SET_PAGINATION_HSM', hsmTemplates.meta);
        commit('SET_HSM_CHANNELS', hsmChannels.items);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async SEARCH_HSM({ commit, state }, params): Promise<void> {
    commit('SET_LOADING', true);
    const searchParams = {
      search: params.search ?? '',
      searchOptions: params.searchOptions ?? {},
      limit: state.pagination.hsmTemplates.itemsPerPage
    };

    await $apollo
      .query({ query: query.SEARCH_HSM, variables: { ...searchParams } })
      .then(({ data: { hsmTemplates } }) => {
        commit('SET_LIST_HSM', hsmTemplates.items);
        commit('SET_PAGINATION_HSM', hsmTemplates.meta);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async GET_CHECK_CHANNEL_NOT_DIALOG({ commit }) {
    await $apollo
      .query({ query: query.GET_CHECK_CHANNEL_NOT_DIALOG })
      .then(({ data }) => {
        commit('SET_CHANNELS_NOT_DIALOG', data.checkChannelsNotDialog);
      })
      .catch(() => {
        notification('error');
      });
  },
  async GET_CHANNELS_SIDEBAR({ commit }, params) {
    commit('SET_lOADING_SIDEBAR', true);
    await $apollo
      .query({ query: query.GET_HSM_CHANNELS, variables: { ...params } })
      .then(({ data: { hsmChannels } }) => {
        const channels = hsmChannels.items.filter(
          channel => channel.status !== 'disabled'
        );
        commit('SET_CHANNELS_SIDEBAR', channels);
      })
      .catch(() => {
        notification('error');
      })
      .finally(() => {
        commit('SET_lOADING_SIDEBAR', false);
      });
  },
  async GET_HSM_CHANNELS({ commit }, params): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .query({ query: query.GET_HSM_CHANNELS, variables: { ...params } })
      .then(({ data: { hsmChannels } }) => {
        commit('SET_HSM_CHANNELS', hsmChannels.items);
      })
      .catch(() => {
        notification('error');
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async GET_SELECT_DEVICES({ commit }, params) {
    commit('SET_LOADING_SELECT', true);
    await $apollo
      .query({ query: query.GET_HSM_CHANNELS, variables: { ...params } })
      .then(({ data: { hsmChannels } }) => {
        commit('SET_LIST_SELECT_DEVICES', hsmChannels.items);
        commit('SET_PAGINATION_SELECT_DEVICES', hsmChannels.meta);
      })
      .catch(() => {
        notification('error');
      })
      .finally(() => {
        commit('SET_LOADING_SELECT', false);
      });
  },
  async GET_SELECT_DEVICES_SEARCH({ commit, state }, search) {
    if (search && search.length >= 3) {
      commit('SET_LOADING_SELECT', true);
      await $apollo
        .query({ query: query.GET_HSM_CHANNELS, variables: { search } })
        .then(({ data: { hsmChannels } }) => {
          const { items } = hsmChannels;
          items.forEach((channel: Icommon) => {
            const index = state.listSelectDevices.findIndex(
              (item: Icommon) => item._id === channel._id
            );

            if (index === -1) {
              commit('ADD_ITEM_SELECT_DEVICES', channel);
            }
          });
        })
        .catch(() => {
          notification('error');
        })
        .finally(() => {
          commit('SET_LOADING_SELECT', false);
        });
    }
  },
  async SAVE_HSM({ commit, dispatch }, params): Promise<void> {
    commit('SET_LOADING', true);
    const templateParams: Icommon = {
      template: params
    };

    await $apollo
      .mutate({
        mutation: mutation.SAVE_HSM,
        variables: prepareGraphQLObject(templateParams)
      })
      .then(
        async (): Promise<void> => {
          notification('success');
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .catch(error => {
        if (error.message) {
          notification('error', {
            message: translate.value.errors[error.message]
          });
        } else {
          notification('error');
        }
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async DELETE_HSM({ commit, dispatch }, id: string[]): Promise<void> {
    commit('SET_LOADING', true);
    await $apollo
      .mutate({ mutation: mutation.DELETE_HSM, variables: { id } })
      .then(
        async (): Promise<void> => {
          notification('success');
          await dispatch('REQUEST_PAGINATION');
        }
      )
      .catch(() => {
        notification('error');
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async VIRTUAL_SCROLL_SELECT_DEVICES(
    { commit, state },
    { direction, to, ref }
  ) {
    const pagination = state.pagination.selectPaginationdevices;
    const lastIndex = state.listSelectDevices.length - 1;
    if (
      state.loadingSelect !== true &&
      direction === 'increase' &&
      to === lastIndex
    ) {
      const limit = pagination.itemsPerPage;
      const page_next = +pagination.currentPage + 1;
      let page = 0;

      if (page_next <= pagination.totalPages) {
        page = page_next;
      } else {
        return false;
      }

      commit('SET_LOADING_SELECT', true);

      await $apollo
        .query({
          query: query.GET_SELECT_DEVICES_PAGINATION,
          variables: {
            page,
            limit,
            search: ''
          }
        })
        .then(({ data: { hsmChannels } }) => {
          commit('ADD_LIST_SELECT_DEVICES', hsmChannels.items);
          commit('SET_PAGINATION_SELECT_DEVICES', hsmChannels.meta);
        })
        .finally(() => {
          commit('SET_LOADING_SELECT', false);
          ref.refresh();
        });
    }
  },
  async REQUEST_PAGINATION({ commit, state }, params): Promise<any> {
    commit('SET_LOADING', true);
    const getParams = params || {
      page: 1,
      ...transformFilterObject(state.filter)
    };

    await requestPagination(query.GET_HSM_PAGINATION, {
      ...getParams,
      limit: state.pagination.hsmTemplates.itemsPerPage
    })
      .then(({ data: { hsmTemplates } }) => {
        commit('SET_LIST_HSM', hsmTemplates.items);
        commit('SET_PAGINATION_HSM', hsmTemplates.meta);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  }
};
